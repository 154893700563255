import { Directive, HostBinding, Signal, computed, inject, input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '@pt/environment';
import { UaParserService } from '@pinup-teams/common';

export enum SlackLinkType {
  Channel = 'channel',
  User = 'user',
}

const SLACK_TEAM_ID = environment.slackTeamId;
const SLACK_WORKSPACE = environment.slackWorkspace;

@Directive({
  selector: '[puSlackLink]',
  standalone: true,
})
export class SlackLinkDirective {
  private _uaParserService = inject(UaParserService);
  private _sanitizer = inject(DomSanitizer);

  slackId = input.required<string>({ alias: 'puSlackLink' });
  linkType = input<SlackLinkType>(SlackLinkType.User);

  safeSlackLink: Signal<SafeResourceUrl> = computed(() => {
    const slackLink = this.#buildSlackLink(this.slackId(), this.linkType());
    return this._sanitizer.bypassSecurityTrustResourceUrl(slackLink);
  });

  @HostBinding('attr.href') get href() {
    return this.safeSlackLink();
  }

  #buildSlackLink(id: string, type: SlackLinkType) {
    if (!this._uaParserService.isDesktop) {
      return `slack://${type}?team=${SLACK_TEAM_ID}&id=${id}`;
    }

    return type === SlackLinkType.Channel
      ? this.#buildDesktopChannelLink(id)
      : this.#buildDesktopUserLink(id);
  }

  #buildDesktopChannelLink(id: string): string {
    return `https://${SLACK_WORKSPACE}.slack.com/archives/${id}`;
  }

  #buildDesktopUserLink(id: string): string {
    return `https://${SLACK_WORKSPACE}.slack.com/team/${id}`;
  }
}
