import {
  Directive, effect, ElementRef, inject, input, numberAttribute, Renderer2,
} from '@angular/core';

@Directive({
  selector: '[puBorderColor]',
  standalone: true,
})
export class BorderColorDirective {
  index = input<number, unknown>(null, { alias: 'puBorderColor', transform: numberAttribute });

  private _elementRef = inject(ElementRef);
  private _renderer = inject(Renderer2);

  private _applyClassEffect = effect(() => {
    const className = `border-variant-${this.index() % 4}`;

    this._renderer.addClass(this._elementRef.nativeElement, className);
  });
}
