import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  input,
  InputSignal,
  OnChanges,
} from '@angular/core';

import { environment } from '@pt/environment';

import { arrayToLinkedList, ListNode } from '../utils/linked-list';

enum ImageSizesSuffix {
  Original = '',
  Compressed = '-cmp',
  Small = '-196',
}

const SMALL_IMAGE_SIZE = 196;

@Directive({
  selector: '[ptImageLoader]',
  standalone: true,
})
export class ImageLoaderDirective implements OnChanges {
  src: InputSignal<string> = input<string | undefined>();
  optimizationEnabled: InputSignal<boolean> = input(environment.imagesOptimizationEnabled);

  #elementRef: ElementRef = inject(ElementRef);
  #actualSizeList: ListNode<ImageSizesSuffix>;

  ngOnChanges() {
    this.#actualSizeList = this.#buildActualSizeList();
    this.#loadImage();
  }

  @HostListener('error') onError(): void {
    this.#loadImage();
  }

  #loadImage() {
    if (!this.#actualSizeList || this.#actualSizeList?.value === null) {
      console.error('Impossible to load image ' + this.src());
    }

    this.#elementRef.nativeElement.src = this.#prepareSrcUrl(
      this.src(),
      this.#actualSizeList.value,
    );
    this.#actualSizeList = this.#actualSizeList.next;
  }

  #buildActualSizeList(): ListNode<ImageSizesSuffix> {
    if (!this.optimizationEnabled()) {
      return arrayToLinkedList([ImageSizesSuffix.Original]);
    }

    return arrayToLinkedList(
      this.#elementRef.nativeElement.width < SMALL_IMAGE_SIZE
        ? [ImageSizesSuffix.Small, ImageSizesSuffix.Compressed, ImageSizesSuffix.Original]
        : [ImageSizesSuffix.Compressed, ImageSizesSuffix.Original],
    );
  }

  #prepareSrcUrl(url: string, suffix: string) {
    return url.replace(
      /(.*?)(\.[a-z]+)?$/,
      (_, body, extension) => {
        return body + (extension ? '' : suffix) + (extension ? extension : '');
      },
    );
  }
}
